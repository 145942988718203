import "./singlePost.css";
import { useLocation } from "react-router";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { imageUrl } from "../../constants/Global";
import "../../assets/css/quill-bubble.css";
import ReactQuill from "react-quill-new";
import { Formats, Modules } from "../../constants/Quill";
import {
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    TelegramShareButton,
    VKShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    TelegramIcon,
    VKIcon,
    WhatsappIcon,
} from "react-share";
import Footer from "../footer/Footer";

export default function SinglePost() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [post, setPost] = useState({});
    const PF = imageUrl + "/api/image/";
    const { user } = useContext(Context);
    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");
    const [updateMode, setUpdateMode] = useState(false);
    const shareUrl = imageUrl + "/post/" + post.id;

    const [ogDesc, setOgDesc] = useState("");
    const [photoName, setPhotoName] = useState("");
    const [postUrl, setPostUrl] = useState("");

    useEffect(() => {
        const getPost = async () => {
            const res = await axios.get("/api/post/" + path);
            setPost(res.data);
            setTitle(res.data.title);
            setDesc(res.data.description);

            setOgDesc(res.data.ogDescription);
            setPhotoName(PF + res.data.photo);
            setPostUrl(imageUrl + "/post/" + res.data.id);
        };
        getPost();
    }, [path]);

    const handleDelete = async () => {
        try {
            await axios.delete("/api/post/" + path);
            window.location.replace("/");
        } catch (err) {}
    };

    const handleUpdate = async () => {
        try {
            await axios.put(`/api/post/${post.id}`, {
                username: user.name,
                title,
                description: desc,
            });
            setUpdateMode(false);
        } catch (err) {}
    };
    return (
        <div className="singlePost">

            <title>{title}</title>
            <meta name="description" content={ogDesc} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={ogDesc} />
            <meta property="og:image" content={photoName} />
            <meta property="og:url" content={postUrl} />

            <div className="singlePostWrapper">
                {post.photo && (
                    <img
                        src={PF + post.photo}
                        alt={title}
                        className="singlePostImg"
                    />
                )}
                {updateMode ? (
                    <input
                        type="text"
                        value={title}
                        className="singlePostTitleInput"
                        autoFocus
                        onChange={(e) => setTitle(e.target.value)}
                    />
                ) : (
                    <h1 className="singlePostTitle">
                        {title}
                        {post.username === user?.name && (
                            <div className="singlePostEdit">
                                <i
                                    className="singlePostIcon far fa-edit"
                                    onClick={() => setUpdateMode(true)}
                                ></i>
                                <i
                                    className="singlePostIcon far fa-trash-alt"
                                    onClick={handleDelete}
                                ></i>
                            </div>
                        )}
                    </h1>
                )}
                <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Автор:&nbsp;
                        <Link
                            to={`/?username=${post.username}`}
                            className="link"
                        >
                            <b>{post.username}</b>
                        </Link>
                    </span>
                    <span className="singlePostDate">
                        {new Date(post.createdDate).toLocaleString("ru", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        })}
                    </span>
                </div>
                {updateMode ? (
                    <ReactQuill
                        className="singlePostDescInput"
                        defaultValue={desc}
                        modules={Modules}
                        formats={Formats}
                        onChange={(e) => setDesc(e)}
                        theme="bubble"
                    />
                ) : (
                    <div
                        className="singlePostDesc"
                        dangerouslySetInnerHTML={{ __html: desc }}
                    />
                )}
                {updateMode && (
                    <button className="singlePostButton" onClick={handleUpdate}>
                        Update
                    </button>
                )}
            </div>
            <div className="singlePostShareButtons">
                <span className="singlePostShareButton">
                    <TelegramShareButton url={shareUrl} title={title}>
                        <TelegramIcon size={32} round />
                    </TelegramShareButton>
                </span>
                <span className="singlePostShareButton">
                    <VKShareButton url={shareUrl} title={title}>
                        <VKIcon size={32} round />
                    </VKShareButton>
                </span>
                <span className="singlePostShareButton">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={title}
                        separator=":: "
                    >
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </span>
                <span className="singlePostShareButton">
                    <OKShareButton url={shareUrl} title={title}>
                        <OKIcon size={32} round />
                    </OKShareButton>
                </span>
            </div>
            {/* <Footer /> */}
        </div>
    );
}
