import axios from "axios"
import { useContext, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Context } from "../../context/Context";
import "./login.css"
import { imageUrl } from "../../constants/Global";

export default function Login() {
    const userRef = useRef();
    const passwordRef = useRef();
    const { dispatch, isFetching } = useContext(Context);
    const [error, setError] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch({ type: "LOGIN_START" })
        setError(false)
        try {
            const res = await axios.post("/api/auth/login?first=" + userRef.current.value + "&last=" + passwordRef.current.value)
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data })
        } catch (err) {
            dispatch({ type: "LOGIN_FAILURE" })
            setError(true)
        }
    }

    return (
        <div className="login">

            <title>Войти</title>
            <meta name="description" content="Страница входа для автора" />
            <meta property="og:title" content="Войти" />
            <meta property="og:description" content="Страница входа для автора" />
            <meta property="og:image" content={imageUrl + "/api/image/logo.png"} />
            <meta property="og:url" content={imageUrl + "/login"} />

            <span className="loginTitle">Вход</span>
            <form className="loginForm" onSubmit={handleSubmit} >
                <label>Имя пользователя</label>
                <input type="text" className="loginInput" placeholder="Имя или email..." ref={userRef} />
                <label>Пароль</label>
                <input type="password" className="loginInput" placeholder="Введите пароль..." ref={passwordRef} />
                <button className="loginButton" type="submit" disabled={isFetching}>Войти</button>
            </form>
            <button className="loginRegisterButton">
                <Link className="link" to="/register">Регистрация</Link>
            </button>
            {error && <span style={{color:"red", marginTop:"10px"}}>Имя или пароль неверные</span>}
        </div>
    )
}
