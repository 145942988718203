import "./write.css";
import { useContext, useState } from "react";
import axios from "axios";
import { Context } from "../../context/Context";
import imageCompression from "browser-image-compression";
import ReactQuill from "react-quill-new";
import Quill from "quill";
import "../../assets/css/quill-bubble.css";
import { Formats, Modules } from "../../constants/Quill";

export default function Write() {
    const [title, setTitle] = useState("");
    const [description, setDesc] = useState("");
    const [hashtags, setHashtags] = useState("");
    const [file, setFile] = useState(null);
    const { user } = useContext(Context);
    const [disabled, setDisabled] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [imageError, setImageError] = useState(false);

    const compressionOptions = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        }
        if (!title || title.trim().length === 0) {
            setTitleError(true);
            return;
        }
        setTitleError(false);
        setImageError(false);
        setDisabled(true);
        const newPost = {
            username: user.name,
            title,
            description,
            hashtags,
        };
        if (file) {
            try {
                const compressedFile = await imageCompression(
                    file,
                    compressionOptions
                );
                const data = new FormData();
                const fileName = Date.now() + file.name;
                data.append("name", fileName);
                data.append("image", compressedFile);
                newPost.photo = fileName;
                await axios.post("/api/image", data);
            } catch (err) {
                setImageError(true);
                setFile(null);
                setDisabled(false);
                return;
            }
        }
        try {
            const res = await axios.post("/api/post", newPost);
            window.location.replace("/post/" + res.data.id);
        } catch (err) {}
    };
    const Link = Quill.import("formats/link");
    Link.sanitize = function (url) {
        // quill by default creates relative links if scheme is missing.
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            return `https://${url}`;
        }
        return url;
    };
    return (
        <div className="write">
            {file && (
                <img
                    className="writeImg"
                    src={URL.createObjectURL(file)}
                    alt=""
                />
            )}
            <form className="writeForm" onSubmit={handleSubmit}>
                <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                        <i className="writeIcon fas fa-plus"></i>
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                    <input
                        type="text"
                        placeholder="Заголовок"
                        className="writeInput"
                        autoFocus={true}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="writeFormGroup">
                    {titleError && (
                        <span style={{ color: "red", marginTop: "10px" }}>
                            Заголовок не может быть пустым
                        </span>
                    )}
                </div>
                <div className="writeFormGroup">
                    {imageError && (
                        <span style={{ color: "red", marginTop: "10px" }}>
                            Не удалось загрузить изображение. Доступны для
                            загрузки только изображения с расширением .jpg,
                            .jpeg, .png и соотношением сторон от 4:5 до 1.9:1.
                            Попробуйте загрузить другое изображение
                        </span>
                    )}
                </div>
                <div className="writeFormGroup">
                    <ReactQuill
                        className="writeInput writeText"
                        placeholder="Расскажите Вашу историю..."
                        modules={Modules}
                        formats={Formats}
                        onChange={(e) => setDesc(e)}
                        theme="bubble"
                    />
                </div>
                <div className="writeFormGroup">
                    <textarea
                        placeholder='Укажите хэштеги через пробел (максимум 10), например: "наукозация наука science"'
                        type="text"
                        className="writeInput writeHashtags"
                        onChange={(e) => setHashtags(e.target.value)}
                    ></textarea>
                </div>
                <button className="writeSubmit" type="submit">
                    {disabled ? "Публикуем..." : "Опубликовать"}
                </button>
            </form>
        </div>
    );
}
