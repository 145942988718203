import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import Sidebar from "../../components/sidebar/Sidebar";
import "./home.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import { imageUrl } from "../../constants/Global";
import Footer from "../../components/footer/Footer";

export default function Home() {
  const postsPerPage = 12;
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    setSelectedPage(0);
    const fetchPosts = async () => {
      const res = await axios.get(
        "/api/post" +
          search +
          (search ? "&" : "?") +
          "size=" +
          postsPerPage +
          "&offset=" +
          0
      );
      setPosts(res.data);
      setTotalCount(res.headers["x-total-count"]);
      setPageCount(Math.ceil(res.headers["x-total-count"] / postsPerPage));
    };
    fetchPosts();
  }, [search]);

  const handlePageClick = (event) => {
    const selected = event.selected;
    const newOffset = (selected * postsPerPage) % totalCount;
    setSelectedPage(selected);
    const fetchPosts = async () => {
      const res = await axios.get(
        "/api/post" +
          search +
          (search ? "&" : "?") +
          "size=" +
          postsPerPage +
          "&offset=" +
          newOffset
      );
      setPosts(res.data);
      setTotalCount(res.headers["x-total-count"]);
      setPageCount(Math.ceil(res.headers["x-total-count"] / postsPerPage));
    };
    fetchPosts();
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1024px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 1024px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  return (
    <>

      <title>Наукозация</title>
      <meta name="description" content="Блог о том, как заниматься наукой в современной России и стать успешным молодым ученым: разбираем успешные и неуспешные примеры, делимся опытом (негативным и позитивным), а также обсуждаем последние новости и события." />
      <meta property="og:title" content="Наукозация" />
      <meta property="og:description" content="Блог о том, как заниматься наукой в современной России и стать успешным молодым ученым: разбираем успешные и неуспешные примеры, делимся опытом (негативным и позитивным), а также обсуждаем последние новости и события." />
      <meta property="og:image" content={imageUrl + "/api/image/logo.png"} />
      <meta property="og:url" content={imageUrl} />
      
      <Header />
      <div className="home">
        <Posts posts={posts} />
        {matches && <Sidebar />}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="<"
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        forcePage={selectedPage}
        containerClassName="pagination"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        activeClassName="active"
      />
      {/* <Footer /> */}
    </>
  );
}
