import Sidebar from "../../components/sidebar/Sidebar"
import SinglePost from "../../components/singlePost/SinglePost"
import "./single.css"
import { useEffect, useState } from "react";

export default function Single() {

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 1024px)").matches
      )
    
      useEffect(() => {
        window
        .matchMedia("(min-width: 1024px)")
        .addEventListener('change', e => setMatches( e.matches ));
      }, []);

    return (
        <div className="single">
            <SinglePost/>
            {matches && <Sidebar/>}
        </div>
    )
}
