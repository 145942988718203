import "./newAuthor.css";
import photo from "./../../assets/images/new-author.jpg";
import { imageUrl } from "../../constants/Global";

export default function NewAuthor() {
    return (
        <div className="newAuthor">
            
            <title>Стань Автором</title>
            <meta name="description" content="Проект Наукозация приглашает к публикации" />
            <meta property="og:title" content="Стань Автором" />
            <meta property="og:description" content="Проект Наукозация приглашает к публикации" />
            <meta property="og:image" content={imageUrl + "/api/image/logo.png"} />
            <meta property="og:url" content={imageUrl + "/newAuthor"} />

            <p>
                <strong>CALL FOR PAPERS</strong>
            </p>
            <p>
                <strong>Проект "Наукозация" приглашает к публикации.</strong>
            </p>
            <p>
                <strong>Расскажи свою историю:</strong> пришли нам короткую
                статью.
            </p>
            <p>
                <strong>Объем статьи:</strong> 2.000 - 5.000 знаков с пробелами
                по тематике «Наука», «Научная деятельность», «Преподавание»,
                «Личный опыт (позитивный или негативный) работы в науке и
                образовании», «Социальные и гуманитарные науки».
            </p>
            <p>
                <strong>Язык публикации:</strong> русский, английский,
                испанский.
            </p>
            <p>
                <strong>
                    Все статьи будут размещены на главном сайте "Наукозация", а
                    также выгружены на страницы проекта в социальных сетях.
                </strong>
            </p>
            <p>По желанию авторов статьи могут быть опубликованы анонимно.</p>
            <p>
                <strong>Контакты:</strong> naukozaciya@yandex.ru
            </p>
            <img src={photo} alt="Фото" />
        </div>
    );
}
