import "./services.css";
import photo from "./../../assets/images/services.jpg";
import { imageUrl } from "../../constants/Global";

export default function Services() {
    return (
        <div className="services">

            <title>Платные услуги</title>
            <meta name="description" content="Латинская Америка - как на ладони" />
            <meta property="og:title" content="Платные услуги" />
            <meta property="og:description" content="Латинская Америка - как на ладони" />
            <meta property="og:image" content={imageUrl + "/api/image/logo.png"} />
            <meta property="og:url" content={imageUrl + "/services"} />

            <p>
                <strong>ЛАТИНСКАЯ АМЕРИКА - КАК НА ЛАДОНИ</strong>
            </p>
            <p>
                - Провожу персональные онлайн-лекции по тематикам Латинская
                Америка и Карибский бассейн, внешняя политика США и России в
                регионе.
            </p>
            <p>
                - Информационно-аналитические услуги российским компаниям по
                вопросам международных отношений, политики и экономики стран
                Латинской Америки и Карибского бассейна.
            </p>
            <p>
                кандидат политических наук,{"\n"}
                <strong>Екатерина Юрьевна Косевич</strong>
            </p>
            <p>
                <strong>Контакты:</strong> naukozaciya@yandex.ru
            </p>
            <img src={photo} alt="Фото" />
        </div>
    );
}
